/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W marcu 2024 roku została podpisana umowa na zadanie inwestycyjne pn. „Rozbudowa drogi gminnej nr 090513C ul. Wiejskiej w Szubinie”.  Rozbudową zostanie objęty odcinek od km 1+330 do km 2+718 oraz od km 0+000 do km 0+020."), "\n", React.createElement(_components.p, null, "W ramach prac budowlanych zostaną zrealizowane zadania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "budowa jezdni o nawierzchni z mieszanki mineralno-asfaltowej,"), "\n", React.createElement(_components.li, null, "przebudowa istniejących i budowa nowych zjazdów o nawierzchni z kostki betonowej,"), "\n", React.createElement(_components.li, null, "budowa ścieżki pieszo-rowerowej, dojść do posesji, poboczy,"), "\n", React.createElement(_components.li, null, "budowa drogowego rowu odwadniającego oraz przepustów pod zjazdami,"), "\n", React.createElement(_components.li, null, "wycinka drzew i krzewów wraz z nowym nasadzeniem,"), "\n", React.createElement(_components.li, null, "montaż skrzynek dla ptaków i nietoperzy,"), "\n", React.createElement(_components.li, null, "budowa kanału technologicznego,"), "\n", React.createElement(_components.li, null, "przebudowa infrastruktury teletechnicznej i gazowej,"), "\n", React.createElement(_components.li, null, "budowa oświetlenia ulicznego,"), "\n", React.createElement(_components.li, null, "budowa kanalizacji deszczowej,"), "\n", React.createElement(_components.li, null, "wykonanie oznakowania pionowego i poziomego."), "\n"), "\n", React.createElement(_components.p, null, "Termin realizacji przewidywany jest na 9 miesięcy od daty podpisania umowy. Inwestycja planowo powinna być oddana do użytku pod koniec roku 2024."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
